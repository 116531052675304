import "babel-polyfill";
import '@formatjs/intl-locale/polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ConnectedIntlProvider from './app/ConnectedIntlProvider.js'
import routes from 'Routes.jsx'
import { createStore, applyMiddleware }  from 'redux'
import {thunk} from 'redux-thunk'
import reducer from 'app/reducer.js'

//styles
import "styles/main.scss";

const store = createStore(reducer, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <ConnectedIntlProvider>
            <AppContainer>
                <BrowserRouter>{routes}</BrowserRouter>
            </AppContainer>
        </ConnectedIntlProvider>
    </Provider>,
    document.getElementById("app")
);

if (module.hot) {
    module.hot.accept();
}
